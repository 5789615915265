
import { Component, Vue } from "vue-property-decorator";
import SendTreeTicketForm from "@/components/forms/SendTreeTicketForm.vue";
import FormCard from "@/components/FormCard.vue";

@Component({
  components: {
    SendTreeTicketForm,
    FormCard
  }
})
export default class SendTreeRequest extends Vue {
  private mounted() {
    window.scrollTo(0, 0);
  }
}
