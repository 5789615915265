
import { Component, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import SendTreeRequest from "@/components/request/SendTreeRequest.vue";
import IdentifyUser from "@/components/request/IdentifyUser.vue";

@Component({
  components: {
    LogoSingle,
    SendTreeRequest,
    IdentifyUser,
    EndRequest: () => import("@/components/request/EndRequest.vue")
  }
})
export default class TreeRequest extends Vue {
  private mounted() {
    this.$store.dispatch("setStateToTwo");
    this.$store.dispatch("cleanTicket");
    window.scroll(0, 0);
  }
}
